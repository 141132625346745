import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2a2c32',
        secondary: '#7C7E8B',
        accent: '#F5FAFE',
        error: '#eb0043',
        info: '#2196F3',
        success: '#00c479',
        warning: '#FFC107',
        background: '#F6F7FB',
        dashboard_background: '#FFFFFF',
        disabled_item: '#d6d6d7',
        expressive_green: "#00c479",
        disabled2: '#B6BAC5',
        lightgray_background: '#F9F9F9',
        border: "#ebebeb",
        border_dark: "#34404c",
        icons: "#7C7E8B",
        labels: "#8C8B88"
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    iconfont: 'mdi',
  }
})
