const ModelLocalStorage = {
    get(key, defaultValue) {
        var result = defaultValue;
        try {
            result = JSON.parse(localStorage.getItem(key));
        } catch (e) {
            result = defaultValue;
        }
        if (result == null) {
            result = defaultValue;
        }
        return result;
    },
    save(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    remove(key) {
        localStorage.removeItem(key)
    },
}

export default ModelLocalStorage;