import ApiService from './api.service';
import UserStorage from './userstorage.service'
import TokenService from './token.service';
import store from "../store/index";

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    logout: async function (logoutFromAllDevices=false) {
        if (logoutFromAllDevices) {
            try {
                const requestData = {
                    method: 'get',
                    url: "/logout/",
                }
                await ApiService.customRequest(requestData);
            } catch {
                console.log("Bad token");
            }
        }

        store.dispatch("ui/reset");
        store.dispatch("autosave/reset");

        TokenService.removeToken();
        ApiService.removeHeader();
        UserStorage.removeFirstName();
        UserStorage.removeLastName();
        UserStorage.removeAccessLevel();
    }
}

export default { UserService }

export { UserService, AuthenticationError }
