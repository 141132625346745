import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import ui from './modules/ui'
import autosave from "./modules/autosave"
import editor from "./modules/editor"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    autosave,
    editor,
  },
  strict: debug,
})
