const TOKEN_KEY = "access_token";

const TokenService = {
  getToken() {
    try {
      return sessionStorage.getItem(TOKEN_KEY);
    } catch {
      if (!alert("Dodatek medyczny nie jest dostępny w trybie incognito.")) {
        window.parent.postMessage(
          {
            name: "partnerApp.back",
            data: {},
          },
          document.referrer
        );
      }
    }
  },
  saveToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken);
  },
  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  },
};

export default TokenService;
