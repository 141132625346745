import Vue from "vue";
import Router from "vue-router";
import TokenService from "@/services/token.service";
import AuthService from "@/services/auth.service";
import ROUTE_STATES from "@/constants/route-states";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "",
      component: () => import("@/views/RootView.vue"),
      children: [
        {
          path: "/patient",
          name: "patient",
          component: () => import("@/views/patients/PatientView.vue"),
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/Dashboard.vue"),
        },
      ],
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("@/views/auth/Overview.vue"),
    },
    {
      path: "*",
      name: "page-not-found",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
});

const stateNavigator = (url) => {
  const isLoggedIn = !!TokenService.getToken();

  if (!isLoggedIn && url.name !== "auth") {
    return {
      name: "auth",
      query: { redirect: url.fullPath },
      replace: true
    };
  } else if (isLoggedIn && !url.query.state && url.name !== "page-not-found") {
    return {
      name: "page-not-found",
      params: { pathMatch: "" },
      replace: true
    };
  } else if (
    isLoggedIn &&
    ROUTE_STATES[url.query.state] === "settings" &&
    url.name !== "settings"
  ) {
    return {
      name: "settings",
      params: { pathMatch: "" },
      query: url.query,
      replace: true
    };
  } else if (
    isLoggedIn &&
    ROUTE_STATES[url.query.state] === "patient" &&
    url.name !== "patient"
  ) {
    return {
      name: "patient",
      params: { pathMatch: "" },
      query: url.query,
      replace: true
    };
  }
  return {};
};

router.beforeEach((to, from, next) => {
  if (!!TokenService.getToken() && !AuthService.compareAuthParams(to)) {
    TokenService.removeToken();
  }
  console.log(from, to);
  return next(stateNavigator(to, from));
});

export default router;
