import ModelLocalStorage from "../local/model"

const TOOL = "tool";
const TOOL_CATEGORY = "tool_category";
const TOOL_SUBCATEGORY = "tool_subcategory";
const TOOL_FROM_SHAPES = "tool_from_shapes";
const TOOL_FROM_DASHED_SHAPES = "tool_from_dashed_shapes";
const TOOL_PENCIL = "tool_pencil";
const TOOL_CURVE = "tool_curve";
const TOOL_STROKE_WIDTH = "tool_stroke_width";
const TOOL_SIZE = "tool_size";
const TOOL_COLOR = "tool_color";
const TOOL_DASHED = "tool_dashed";

const state = {
    tool: ModelLocalStorage.get(TOOL, "selectMode"),
    toolCategory: ModelLocalStorage.get(TOOL_CATEGORY, "selectMode"),
    toolSubcategory: ModelLocalStorage.get(TOOL_SUBCATEGORY, "selectMode"),
    toolCurve: ModelLocalStorage.get(TOOL_CURVE, "curve"),
    toolStrokeWidth: ModelLocalStorage.get(TOOL_STROKE_WIDTH, 5),
    toolSize: ModelLocalStorage.get(TOOL_SIZE, 20),
    toolColor: ModelLocalStorage.get(TOOL_COLOR, "#bdc3c7"),
    toolDashed: ModelLocalStorage.get(TOOL_DASHED, "false"),
}

const mutations = {
    setTool(state, value) {
        state.tool = value;
    },
    setToolCategory(state, value) {
        state.toolCategory = value;
    },
    setToolSubcategory(state, value) {
        state.toolSubcategory = value;
    },
    setToolType(state, value) {
        state.toolType = value;
    },
    setToolDashed(state, value) {
        state.toolDashed = value;
    },
    changeToolCurve(state, value) {
        state.toolCurve = value;
    },
    setToolStrokeWidth(state, value) {
        state.toolStrokeWidth = value;
    },
    setToolSize(state, value) {
        state.toolSize = value;
    },
    setToolColor(state, value) {
        state.toolColor = value;
    }
}

const actions = {
    async changeTool({ commit }, value) {
        commit("setTool", value);
    },
    async changeToolType({ commit }, value) {
        commit("setToolType", value);
    },
    async changeToolColor({ commit }, value) {
        commit("setToolColor", value);
    },
    async changeToolSize({ commit }, value) {
        commit("setToolSize", value);
    },
    async changeToolStrokeWidth({ commit }, value) {
        commit("setToolStrokeWidth", value);
    },
    async changeToolCategory({ commit }, value) {
        commit("setToolCategory", value);
    },
    async changeToolSubcategory({ commit }, value) {
        commit("setToolSubcategory", value);
    },
    async changeToolDashed({ commit }, value) {
        commit("setToolDashed", value);
    }
}

export const editor = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default editor;