import router from "@/router";

const BooksyService = {
  back: () => {
    if (
      process.env.NODE_ENV !== "production" ||
      localStorage.getItem("BACK") !== null
    ) {
      router.replace({
        name: "settings",
        query: {
          ...router.currentRoute.query,
          state: localStorage.getItem("BACK")
        },
      });
      return;
    }

    window.parent.postMessage(
      {
        name: "partnerApp.back",
        data: {},
      },
      document.referrer
    );
  },
  medicalAddonEnabled: () => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    window.parent.postMessage(
      {
        name: "partnerApp.installed",
        data: {},
      },
      document.referrer
    );
  },
  redirectToCalendar: (appointmentUid) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    window.parent.postMessage(
      {
        name: "partnerApp.redirectToAppointment",
        data: { appointmentUid },
      },
      document.referrer
    );
  },
  booksyMedIsReady: () => {
    window.parent.postMessage(
      {
        name: "partnerApp.created",
        data: {},
      },
      document.referrer
    );
  }
};

export default BooksyService;
