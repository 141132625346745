<template>
  <router-view class="view"></router-view>
</template>

<script>
import BooksyService from "@/services/booksy.service";

export default {
  created() {
    BooksyService.booksyMedIsReady();
  }
}
</script>


<style>
html {
   overflow-y: auto !important;
}
</style>


